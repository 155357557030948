<template>
    <div class="cursor-pointer border-2 client-card text-base-content" @click="$emit('click')" :class="selected ? 'border-primary' : 'border-transparent'" :title="client.name">
        <div class="flex items-center h-10">
            <div class="px-4  flex bg- justify-center w-14 py-2  items-center" v-once  :class="getRandomColors()">{{nameToShort(client.name)}}</div>
            <span class="client-card-info px-4 self-stretch  w-60 truncate text-sm font-medium  bg-card-bg hover:bg-indigo-100 capitalize  py-2" :class="{'bg-indigo-200' : selected }">{{client.name}} </span>
        </div>
    </div>
</template>
<script>
import {getRandomColors, nameToShort} from "@/plugins/functions"
export default {
    name:"client-card",
    components:{},
    computed:{

    },
    props:["client", "progress", "selected"],
    data(){
        return{}
    },
    methods: {
        getRandomColors,
        nameToShort,
    }
}
</script>
<style scoped>

</style>